<template>
    <div class="app-skeleton">
        <b-skeleton-img no-aspect width="100%" height="240px" class="mb-4 rounded" />
        <b-skeleton width="65%" />
        <b-skeleton width="85%" />
    </div>
</template>

<script>
export default {
    name: 'AppSkeleton',
};
</script>

<style lang="scss">
    .app-skeleton {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        text-align: left;
        width: 100%;
    }
</style>
