<template>
    <div class="landing-page">
        <hero />
        <access />
        <div class="container">
            <hr />
        </div>
        <apps />
        <features />
        <join />
    </div>
</template>

<script>
import Access from '@/pages/landing/components/access.vue';
import Apps from '@/pages/landing/components/apps.vue';
import Features from '@/pages/landing/components/features.vue';
import Hero from '@/pages/landing/components/hero.vue';
import Join from '@/pages/landing/components/join.vue';

export default {
    name: 'landing-page',
    components: {
        Access,
        Apps,
        Features,
        Hero,
        Join,
    },
    mounted() {
        if(this.$route.query.logout) {
            sessionStorage.removeItem('_atid')
            this.$cookies.remove('_atid')
            this.$cookies.remove('_atfa')
            this.$cookies.remove('_astfa')
        }
    }
};
</script>
