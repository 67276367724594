<template>
    <div id="apps" class="apps">
        <div class="container">
            <h2 class="apps__title">
                {{ $t('Some apps that use Accounts') }}
            </h2>
            <div class="row">
                <template v-if="loading">
                    <div class="col-lg-4 mb-5 mb-lg-0" v-for="index in 3" :key="index">
                        <app-skeleton />
                    </div>
                </template>
                <template v-else>
                    <div class="col-md-6 col-lg-4" v-for="site in sites" :key="site.site_id">
                        <div class="apps__item" v-if="site.imageUrl">
                            <div class="apps__logo-container" :class="site.class">
                                <img :src="site.imageUrl" class="img-fluid" alt="Tendencys" loading="lazy" />
                            </div>
                            <h3 class="apps__logo-title">
                                {{ site.name }}
                            </h3>
                            <p class="apps__logo-description">
                                {{ $t(site.message) }}
                            </p>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import AppSkeleton from '@/components/ui/skeletons/AppSkeleton.vue';

export default {
    name: 'apps',
    components: { AppSkeleton },
    data() {
        return {
            loading: true,
            sites: [],
        }
    },
    mounted() {
        this.getSites();
    },
    methods: {
        async getSites() {
            this.loading = true;
            await this.axiosAccount.get('/api/sites').then(response => {
                response.data.map(site => {
                    switch(site.name) {
                        case 'Ecart Seller':
                            site.imageUrl = require('@/assets/images/home/logo-ecart.svg') || null;
                            site.message = 'Create and manage your online store.';
                            site.class = 'apps__logo-container--ecartseller';
                        break;
                        case 'Ecart Pay':
                            site.imageUrl = require('@/assets/images/home/logo-ecartpay.svg') || null;
                            site.message = 'Create payment codes, send and receive money.';
                            site.class = 'apps__logo-container--ecartpay';
                        break;
                        case 'Returns':
                            site.imageUrl = require('@/assets/images/home/logo-returns.svg') || null;
                            site.message = 'Manage your store returns in one place.';
                            site.class = 'apps__logo-container--returns';
                        break;
                        case 'Envia':
                            site.imageUrl = require('@/assets/images/logos/envia.svg') || null;
                            site.message = 'Send your products to anywhere.';
                            site.class = 'apps__logo-container--envia';
                            site.darkClass = 'dark';
                        break;
                        case 'Partners':
                            site.imageUrl = require('@/assets/images/home/logo-partners.svg') || null;
                            site.message = 'Connects companies with the best online logistics software.';
                            site.class = 'apps__logo-container--partners';
                        break;
                        case 'Fulfillment':
                            site.imageUrl = require('@/assets/images/home/logo-fulfillment.svg') || null;
                            site.message = 'The fastest and most efficient international fulfillment network.';
                            site.class = 'apps__logo-container--fulfillment';
                            site.darkClass = 'dark';
                        break;
                        case 'WMS':
                            site.imageUrl = require('@/assets/images/home/logo-wms.svg') || null;
                            site.message = 'The most complete cloud-based Warehouse Management System in the market.';
                            site.class = 'apps__logo-container--wms';
                            site.darkClass = 'dark';
                        break;
                    } 
                });
                this.sites = response.data;
                
                if (this.$route.hash && this.$route.hash === '#apps') {
                    document.getElementById('apps').scrollIntoView({
                        block: 'start',
                        behavior: 'smooth',
                    });
                }
            });
            setTimeout(() => {
                this.loading = false;
            }, 500);
        },
        getUrl(page, site, redirect) {
            return `/${page}?site_id=${site}&redirect_url=${redirect}`;
        },
    },
};
</script>

<style lang="scss">
.apps {
    padding-top: 100px;
    padding-bottom: 100px;

    @media(max-width: 768px) {
        padding-top: 70px;
        padding-bottom: 70px;
        text-align: center;
    }

    &__title {
        font-weight: 600;
        font-size: 35px;
        margin-bottom: 45px;
        color: #202225;

        @media(max-width: 768px) {
            font-size: 28px;
            margin-bottom: 35px;
        }
    }

    &__logo-container {
        width: 100%;
        height: 240px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000000;
        border-radius: 15px;
        position: relative;
        margin-bottom: 25px;
        padding: 20px;

        &--ecartpay {
            background-color: #68AE34;
        }

        &--returns {
            background-color: #002A60;
        }

        &--envia {
            background-color: #dae9eb;
        }

        &--partners {
            background-color: #000B48;
        }

        &--fulfillment {
            background-color: #ecf0ff;
        }

        &--wms {
            background-color: #ecf0ff;
        }

        img {
            width: 100%;
            max-width: 200px;
        }
    }

    &__nav {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        justify-content: center;
    }

    &__nav-item {
        position: relative;

        &:not(:last-child) {
            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: calc(50% - 7.5px);
                height: 15px;
                width: 1px;
                background: rgba(#ffffff, 0.50);

            }
        }

        &.dark {
            &:not(:last-child):after {
                background: black;
            }
        }
    }

    &__nav-link {
        font-weight: 500;
        color: #ffffff;
        padding: 0.7rem 1rem !important;

        &:hover {
            color: #ececec;
        }

        &.dark {
            color:black;

            &:hover {
                color: #555;
            }
        }
    }

    &__logo-title {
        font-size: 23px;
        color: #202225;
        font-weight: 600;
    }

    &__logo-description {
        color: #787F84;
        font-weight: 500;
        font-size: 16px;

        @media(max-width: 768px) {
            margin-bottom: 40px;
        }
    }
}
</style>
